export interface Artist {
  id: number;
  name: string;
  added: Date;
  age: number;
  records: LinkedRecord[];
  recordsCount: number;
  discogsId: number;
  image: string;
}

export interface LinkedRecord {
  id: number;
  title: string;
  year: number;
  medium: string;
  catalogueNumber: string;
  comment?: any;
  added: Date;
  age: number;
  image: string;
}

export class ArtistFormValues {
  id?: number;
  name: string = "";
  discogsId: number = 0;

  constructor(init?: Artist) {
    if (init) {
      this.id = init.id;
      this.name = init.name;
      this.discogsId = init.discogsId;
    }
  }
}

export interface ArtistOption {
  id: number;
  name: string;
  discogsId: number;
}
