import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import { Menu } from "@mui/icons-material";
import { IconButton, styled, Toolbar, Typography } from "@mui/material";
import { drawerWidth } from "../common/styles";
import { ToggleColorMode } from "./ColorMode";
import SearchForm from "../../features/search/SearchForm";

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

interface IProps {
  open: boolean;
  toggleOpen: () => void;
}

export default function TopBar(props: IProps) {
  const { open, toggleOpen } = props;

  return (
    <AppBar position="absolute" open={open} enableColorOnDark>
      <Toolbar sx={{ pr: "24px" }}>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={toggleOpen}
          sx={{ marginRight: "36px", ...(open && { display: "none" }) }}
        >
          <Menu />
        </IconButton>
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          sx={{ flexGrow: 1 }}
        >
          LpsCore
        </Typography>

        

        <SearchForm openSidebar />

        <ToggleColorMode />
      </Toolbar>
    </AppBar>
  );
}
