import { Delete, Edit } from "@mui/icons-material";
import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { toast } from "react-toastify";
import { OverflowingCardHeader } from "../../app/layout/CustomizedMuiComponents";
import { Artist } from "../../app/models/artist";
import { useStore } from "../../app/stores/store";
import ArtistDetails from "./ArtistDetails";
import ArtistForm from "./ArtistForm";

interface IProps {
  artist: Artist;
}

export default function ArtistCard(props: IProps) {
  const { artist } = props;

  const {
    modalStore: { openModal },
    artistStore: { deleteArtist, loadArtist },
    accountStore: { isLoggedIn },
  } = useStore();
  const [deleteDialog, setDeleteDialog] = useState(false);
  const handleShow = () => {
    openModal({ size: "sm", content: <ArtistDetails id={artist.id} /> });
  };

  const handleUpdate = () => {
    loadArtist(artist.id).then((artist) =>
      !!artist
        ? openModal({ size: "sm", content: <ArtistForm /> })
        : toast.error("Could not load record")
    );
  };

  return (
    <Card elevation={12} square>
      <CardActionArea onClick={handleShow} >
        <OverflowingCardHeader
          title={artist.name}
          titleTypographyProps={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        />
        <CardMedia
          component="img"
          image={
            artist.image
              ? `${process.env.REACT_APP_RESOURCE_URL}${artist.image}`
              : `/record_ph.png`
          }
          alt={`Image of ${artist.name}`}
          onError={(i: any) => (i.target.src = "/record_ph.png")}
        />
        <CardContent>
          <Typography
            variant="body2"
            color="text.secondary"
          >{`${artist.recordsCount} records`}</Typography>
        </CardContent>
      </CardActionArea>
      {isLoggedIn && (
        <CardActions sx={{ justifyContent: "flex-end" }}>
          <IconButton onClick={handleUpdate}>
            <Edit />
          </IconButton>
          <IconButton onClick={() => setDeleteDialog(true)}>
            <Delete />
          </IconButton>
        </CardActions>
      )}

      <Dialog
        open={deleteDialog}
        onClose={() => setDeleteDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Really delete Artist?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This cannot be reverted. So be sure what you're doing!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialog(false)}>Cancel</Button>
          <Button onClick={() => deleteArtist(artist.id)} autoFocus>
            Yes, I'm sure
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
}
