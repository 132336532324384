import { MoreVert } from "@mui/icons-material";
import {
  Avatar,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { useState } from "react";
import stringAvatar from "../../app/common/utils/avatar";
import { UserAccount } from "../../app/models/account";
import { useStore } from "../../app/stores/store";
import { format } from "date-fns";
import { OverflowingCardHeader } from "../../app/layout/CustomizedMuiComponents";
import AccountForm from "./AccountForm";

interface IProps {
  account: UserAccount;
}

export default function AccountCard(props: IProps) {
  const {
    modalStore: { openModal },
    accountStore: { deleteAccount, isLoggedIn },
  } = useStore();
  const { account } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleUpdate = () => {
    openModal({ size: "xs", header: 'Update existing Account', content: <AccountForm account={account} /> });
  };

  return (
    <>
      <Card>
        <OverflowingCardHeader
          avatar={<Avatar {...stringAvatar(account.username)} />}
          title={account.username}
          titleTypographyProps={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          subheader={account.roles.join(", ")}
          subheaderTypographyProps={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          action={
            <>
              <IconButton
                aria-label="settings"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                <MoreVert />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                transformOrigin={{
                  horizontal: "right",
                  vertical: "top",
                }}
                anchorOrigin={{
                  horizontal: "right",
                  vertical: "bottom",
                }}
              >
                <MenuItem disabled={!isLoggedIn} onClick={() => handleUpdate()}>
                  Update
                </MenuItem>
                <MenuItem
                  disabled={!isLoggedIn}
                  onClick={() => setDeleteDialog(true)}
                >
                  Delete
                </MenuItem>
              </Menu>
            </>
          }
        />
        <CardContent>
          <Typography variant="body2" color="text.secondary">{`Last active: ${
            !!account.lastActive
              ? format(account.lastActive, "d.MM.yyyy, H:m")
              : `never`
          }`}</Typography>
        </CardContent>
      </Card>
      <Dialog
        open={deleteDialog}
        onClose={() => setDeleteDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Really delete Account?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This cannot be reverted. So be sure what you're doing!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialog(false)}>Cancel</Button>
          <Button
            onClick={() =>
              deleteAccount(account.id).then(() => setDeleteDialog(false))
            }
            autoFocus
          >
            Yes, I'm sure
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
