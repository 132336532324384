import { makeAutoObservable, reaction } from "mobx";
import { ServerError } from "../models/serverError";
import { store } from "./store";

export default class CommonStore {
    error: ServerError | null = null;
    token: string | null = localStorage.getItem('jwt');
    appLoaded = false;

    searchTerm: string = "";
    setSearchTerm = (term: string) => {
        this.searchTerm = term;
    }

    constructor() {
        makeAutoObservable(this);

        reaction(
            () => this.token,
            token => {
                if (token)
                    localStorage.setItem('jwt', token);
                else
                    localStorage.removeItem('jwt');
            }
        )

        reaction(
            () => this.searchTerm,
            (term) => {
                store.recordStore.setSearchTerm(term);
                store.artistStore.setSearchTerm(term);
            }
        )
    }

    setServerError(error: ServerError) {
        this.error = error;
    }

    setToken = (token: string | null) => {
        this.token = token;
    }

    setAppLoaded = () => {
        this.appLoaded = true;
    }
}