import { format } from "date-fns";
import { makeAutoObservable, reaction, runInAction } from "mobx";
import { toast } from "react-toastify";
import agent from "../api/agent";
import { Pagination, PagingParams } from "../models/pagination";
import {
  Record,
  DiscogsSearchResult,
  RecordFormValues,
  ListenedTo,
  RecordsStats,
} from "../models/record";
import { store } from "./store";

export const defaultPageSize = 50;
export const defaultOrderBy:
  | "newestAsc"
  | "newestDesc"
  | "titleAsc"
  | "titleDesc" = "titleAsc";
export const defaultDiscogsSearchPageSize = 20;
export const defaultRecordsSearchPageSize = 50;

export default class RecordStore {
  recordRegistry = new Map<number, Record>();
  currentRecord?: Record = undefined;
  loading = false;
  loadingInitial = false;
  pagination: Pagination | null = null;
  pagingParams = new PagingParams();
  predicate = new Map();

  discogsRegistry = new Map<number, DiscogsSearchResult>();
  discogsSearching = false;
  discogsPagination: Pagination | null = null;
  discogsPagingParams = new PagingParams();
  discogsSearchTerm: string = "";

  recordsStats?: RecordsStats = undefined;
  loadingRecordsStats: boolean = false;

  resetDiscogsSearch = () => {
    this.discogsRegistry = new Map();
    this.discogsSearching = false;
    this.discogsPagination = null;
    this.discogsPagingParams = new PagingParams();
    this.discogsSearchTerm = "";
  };

  constructor() {
    makeAutoObservable(this);

    reaction(
      () => this.predicate.keys(),
      () => {
        this.pagingParams = new PagingParams();
        this.recordRegistry.clear();
        this.loadRecords();
      }
    );

    reaction(
      () => this.discogsSearchTerm,
      (term) => {
        this.discogsPagination = null;
        this.discogsPagingParams = new PagingParams();
        this.discogsRegistry.clear();

        if (term.length > 0) this.searchDiscogs();
      }
    );

    reaction(
      () => this.searchTerm,
      (term) => {
        this.searchPagination = null;
        this.searchPagingParams = new PagingParams();
        this.recordSearchResults.clear();

        if (term.length > 0) this.searchRecords();
      }
    );

    reaction(
      () => this.searchOrderBy,
      () => {
        this.searchPagination = null;
        this.searchPagingParams = new PagingParams();
        this.recordSearchResults.clear();

        this.searchRecords();
      }
    );
  }

  setLoadingRecordsStats = (loading: boolean) => {
    this.loadingRecordsStats = loading;
  };

  loadRecordsStats = async () => {
    this.loadingRecordsStats = true;

    try {
      const result = await agent.Records.loadStats();

      this.setRecordsStats(result);
      this.setLoadingRecordsStats(false);
    } catch (error) {
      console.log(error);
      this.setLoadingRecordsStats(false);
    }
  };

  private setRecordsStats = (stats: RecordsStats) => {
    this.recordsStats = stats;
  };

  setLoading = (loading: boolean) => {
    this.loading = loading;
  };

  setLoadingInitial = (loading: boolean) => {
    this.loadingInitial = loading;
  };

  setPagination = (pagination: Pagination) => {
    this.pagination = pagination;
  };

  setPagingParams = (pagingParams: PagingParams) => {
    this.pagingParams = pagingParams;
  };

  setPredicate = (predicate: string, value: string) => {
    switch (predicate) {
      case "keyword":
        this.predicate.delete("keyword");
        this.predicate.set("keyword", value);
        break;
      case "orderBy":
        this.predicate.delete("orderBy");
        this.predicate.set("orderBy", value);
        break;
    }
  };

  get axiosParams() {
    const params = new URLSearchParams();
    params.append("pageNumber", this.pagingParams.pageNumber.toString());
    params.append("pageSize", defaultPageSize.toString());
    this.predicate.forEach((value, key) => {
      params.append(key, value);
    });

    return params;
  }

  get records() {
    return Array.from(this.recordRegistry.values());
  }

  loadRecords = async () => {
    try {
      const result = await agent.Records.list(this.axiosParams);
      result.data.forEach((record) => {
        this.setRecord(record);
      });
      this.setPagination(result.pagination);
      this.setLoadingInitial(false);
    } catch (error) {
      console.log(error);
    }
  };

  private setRecord = (record: Record) => {
    if (!!record.added) record.added = new Date(record.added);

    if (record.listenedToRecord.length > 0) {
      record.listenedToRecord = record.listenedToRecord.map((l) => ({
        onAt: new Date(l.onAt),
      }));
    }

    this.recordRegistry.set(record.id, record);
  };

  setDiscogsSearching = (searching: boolean) => {
    this.discogsSearching = searching;
  };

  setDiscogsPagination = (pagination: Pagination) => {
    this.discogsPagination = pagination;
  };

  setDiscogsPagingParams = (pagingParams: PagingParams) => {
    this.discogsPagingParams = pagingParams;
  };

  setDiscogsSearchTerm = (term: string) => {
    this.discogsSearchTerm = term;
  };

  get discogsAxiosParams() {
    const params = new URLSearchParams();
    params.append("pageNumber", this.discogsPagingParams.pageNumber.toString());
    params.append("pageSize", defaultDiscogsSearchPageSize.toString());
    params.append("keyword", this.discogsSearchTerm);

    return params;
  }

  get discogsResults() {
    return Array.from(this.discogsRegistry.values());
  }

  searchDiscogs = async () => {
    this.discogsSearching = true;

    try {
      const result = await agent.Records.searchDiscogs(this.discogsAxiosParams);
      result.data.forEach((record) => {
        this.setSearchResult(record);
      });
      this.setDiscogsPagination(result.pagination);
      this.setDiscogsSearching(false);
    } catch (error) {
      console.log(error);
      this.setDiscogsSearching(false);
    }
  };

  private setSearchResult = (result: DiscogsSearchResult) => {
    this.discogsRegistry.set(result.discogsId, result);
  };

  loadRecord = async (id: number) => {
    this.loading = true;

    try {
      const result = await agent.Records.details(id);
      this.setCurrentRecord(result);
      this.setLoading(false);
      return result;
    } catch (error) {
      console.log(error);
      this.setLoading(false);
    }
  };

  get currentRecordListeningHistoryByMonth() {
    return Object.entries(
      this.currentRecordListeningHistoryByDate.reduce(
        (listenings, listening) => {
          const month = format(listening.onAt, "yyyy-MM-01");
          listenings[month] = listenings[month]
            ? [...listenings[month], listening]
            : [listening];
          return listenings;
        },
        {} as { [key: string]: ListenedTo[] }
      )
    );
  }

  get currentRecordListeningHistoryByDate() {
    if (
      !!!this.currentRecord ||
      this.currentRecord.listenedToRecord.length === 0
    )
      return [];
    return this.currentRecord.listenedToRecord
      .slice()
      .sort((a, b) => b.onAt.getTime() - a.onAt.getTime());
  }

  setCurrentRecord = (record: Record) => {
    if (!!record.added) record.added = new Date(record.added);

    if (record.listenedToRecord.length > 0) {
      record.listenedToRecord = record.listenedToRecord.map((l) => ({
        onAt: new Date(l.onAt),
      }));
    }

    this.currentRecord = record;
  };

  unsetCurrentRecord = () => {
    this.currentRecord = undefined;
  };

  createRecord = async (values: RecordFormValues) => {
    try {
      await agent.Records.create(values);
      store.modalStore.closeModal();
      toast.success("Record created");
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  updateRecord = async (values: RecordFormValues) => {
    try {
      await agent.Records.update(values);
      store.modalStore.closeModal();
      toast.success("Record updated");
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  deleteRecord = async (id: number) => {
    try {
      await agent.Records.delete(id);
      if (!!this.currentRecord && id === this.currentRecord.id) {
        this.unsetCurrentRecord();
      }
      runInAction(() => {
        this.recordRegistry.delete(id);
        this.recordSearchResults.delete(id);
      });
      toast.success("Record deleted");
      store.modalStore.closeModal();
    } catch (error) {
      console.log(error);
      toast.error("Could not delete record");
    }
  };

  loadDiscogsRelease = async (discogsId: number) => {
    try {
      const result = await agent.Records.getDiscogsRecord(discogsId);
      return this.setDiscogsRelease(result);
    } catch (error) {
      console.log(error);
    }
  };

  private setDiscogsRelease = (release: Record) => {
    if (release.year === 0) release.year = new Date().getFullYear();

    if (release.catalogueNumber === "none") release.catalogueNumber = "";

    if (!!!release.comment) release.comment = "";

    if (release.medium) {
      switch (release.medium) {
        case "LP":
        case "CD":
        case "MC":
        case "Other":
          break;
        case "Vinyl":
          release.medium = "LP";
          break;
        case "Cassette":
          release.medium = "MC";
          break;
        default:
          release.medium = "Other";
          break;
      }
    }

    return release;
  };

  recordSearchResults = new Map<number, Record>();
  searching = false;
  searchPagination: Pagination | null = null;
  searchPagingParams = new PagingParams();
  searchTerm = "";
  searchOrderBy = "";

  setSearching = (searching: boolean) => {
    this.searching = searching;
  };

  setSearchPagination = (pagination: Pagination) => {
    this.searchPagination = pagination;
  };

  setSearchPagingParams = (pagingParams: PagingParams) => {
    this.searchPagingParams = pagingParams;
  };

  setSearchTerm = (searchTerm: string) => {
    this.searchTerm = searchTerm;
  };

  setSearchOrderBy = (orderBy: string) => {
    this.searchOrderBy = orderBy;
  };

  get searchAxiosParams() {
    const params = new URLSearchParams();
    params.append("pageNumber", this.searchPagingParams.pageNumber.toString());
    params.append("pageSize", defaultRecordsSearchPageSize.toString());
    params.append("keyword", this.searchTerm);
    if (this.searchOrderBy.length > 0)
      params.append("orderBy", this.searchOrderBy);

    return params;
  }

  get searchResults() {
    return Array.from(this.recordSearchResults.values());
  }

  searchRecords = async () => {
    this.searching = true;

    try {
      const result = await agent.Records.list(this.searchAxiosParams);
      result.data.forEach((record) => {
        this.setRecordsSearchResult(record);
      });
      this.setSearchPagination(result.pagination);
      this.setSearching(false);
    } catch (error) {
      console.log(error);
      this.setSearching(false);
    }
  };

  private setRecordsSearchResult = (record: Record) => {
    if (!!record.added) record.added = new Date(record.added);

    if (record.listenedToRecord.length > 0) {
      record.listenedToRecord = record.listenedToRecord.map((l) => ({
        onAt: new Date(l.onAt),
      }));
    }

    this.recordSearchResults.set(record.id, record);
  };

  listen = async (id: number) => {
    try {
      await agent.Records.listen(id);

      runInAction(() => {
        if (!!this.currentRecord && this.currentRecord.id === id) {
          this.currentRecord.listenedCount += 1;
          this.currentRecord.listenedToRecord.push({ onAt: new Date() });
        }
      });
      return true;
    } catch (error) {
      console.log(error);
      toast.error("Could not increase listened-counter");
    }
  };
}
