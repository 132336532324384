import { createContext, useContext } from "react";
import AccountStore from "./accountStore";
import ArtistStore from "./artistStore";
import CommonStore from "./commonStore";
import ModalStore from "./modalStore";
import RecordStore from "./recordStore";
import SidebarStore from "./sidebarStore";

interface Store {
  commonStore: CommonStore;
  artistStore: ArtistStore;
  recordStore: RecordStore;
  modalStore: ModalStore;
  accountStore: AccountStore;
  sidebarStore: SidebarStore;
}

export const store: Store = {
  commonStore: new CommonStore(),
  artistStore: new ArtistStore(),
  recordStore: new RecordStore(),
  modalStore: new ModalStore(),
  accountStore: new AccountStore(),
  sidebarStore: new SidebarStore()
};

export const StoreContext = createContext(store);

export function useStore() {
  return useContext(StoreContext);
}
