import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../api/agent";
import {
  AccountFormValues,
  LoginFormValues,
  PasswordFormValues,
  User,
  UserAccount,
} from "../models/account";
import { store } from "./store";
import { toast } from "react-toastify";
import { Pagination, PagingParams } from "../models/pagination";

export const defaultPageSize = 50;
export const defaultOrderBy: "usernameAsc" | "usernameDesc" = "usernameAsc";

export default class AccountStore {
  constructor() {
    makeAutoObservable(this);

    reaction(
      () => this.predicate.keys(),
      () => {
        this.pagingParams = new PagingParams();
        this.accountRegistry.clear();
        this.loadUserAccounts();
      }
    );
  }

  user: User | null = null;
  accountRegistry = new Map<number, UserAccount>();
  currentAccount?: UserAccount = undefined;
  loading = false;
  loadingInitial = false;
  pagination: Pagination | null = null;
  pagingParams = new PagingParams();
  predicate = new Map();

  setLoading = (loading: boolean) => {
    this.loading = loading;
  };

  setLoadingInitial = (loading: boolean) => {
    this.loadingInitial = loading;
  };

  setPagination = (pagination: Pagination) => {
    this.pagination = pagination;
  };

  setPagingParams = (pagingParams: PagingParams) => {
    this.pagingParams = pagingParams;
  };

  setPredicate = (predicate: string, value: string) => {
    switch (predicate) {
      case "keyword":
        this.predicate.delete("keyword");
        this.predicate.set("keyword", value);
        break;
      case "orderBy":
        this.predicate.delete("orderBy");
        this.predicate.set("orderBy", value);
        break;
    }
  };

  get axiosParams() {
    const params = new URLSearchParams();
    params.append("pageNumber", this.pagingParams.pageNumber.toString());
    params.append("pageSize", defaultPageSize.toString());
    this.predicate.forEach((value, key) => {
      params.append(key, value);
    });

    return params;
  }

  get accounts() {
    return Array.from(this.accountRegistry.values());
  }

  loadUserAccounts = async () => {
    try {
      const result = await agent.Account.list(this.axiosParams);
      result.data.forEach((account) => {
        this.setUserAccount(account);
      });
      this.setPagination(result.pagination);
    } catch (error) {
      console.log(error);
    }
  };

  private setUserAccount = (account: UserAccount) => {
    if (account.lastActive) account.lastActive = new Date(account.lastActive);
    else account.lastActive = undefined;

    this.accountRegistry.set(account.id, account);
  };

  get isLoggedIn() {
    return !!this.user;
  }

  login = async (credentials: LoginFormValues) => {
    try {
      const user = await agent.Account.login(credentials);
      store.commonStore.setToken(user.token);
      runInAction(() => (this.user = user));
      toast.success("Welcome");
      store.modalStore.closeModal();
    } catch (error) {
      throw error;
    }
  };

  logout = () => {
    store.commonStore.setToken(null);
    this.user = null;
  };

  getUser = async () => {
    try {
      const user = await agent.Account.current();
      store.commonStore.setToken(user.token);
      runInAction(() => (this.user = user));
    } catch (error) {
      console.log(error);
    }
  };

  createAccount = async (values: AccountFormValues) => {
    try {
      await agent.Account.register(values);
      this.loadUserAccounts();
      toast.success("Account created");
      store.modalStore.closeModal();
    } catch (error) {
      throw error;
    }
  };

  updatePassword = async (values: PasswordFormValues) => {
    try {
      await agent.Account.changePassword(values);
      toast.success("Password updated");
      store.modalStore.closeModal();
    } catch (error) {
      throw error;
    }
  };

  updateAccount = async (values: AccountFormValues) => {
    try {
      await agent.Account.update(values.id!, values);
      runInAction(() => this.accountRegistry.clear());
      this.loadUserAccounts();
      toast.success("Account updated");
      store.modalStore.closeModal();
    } catch (error) {
      throw error;
    }
  };

  deleteAccount = async (id: number) => {
    try {
      await agent.Account.delete(id);
      runInAction(() => this.accountRegistry.delete(id));
      toast.success("Account deleted");
    } catch (error) {
      toast.error("Account could not be deleted");
    }
  };
}
