import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { useStore } from "../../stores/store";

export default observer(function ModalComponent() {
  const {
    modalStore: { modal, closeModal },
  } = useStore();

  return (
    <Dialog fullWidth={!!modal.size} onClose={closeModal} open={modal.open} maxWidth={modal.size}>
      {!!modal.header && <DialogTitle>{modal.header}</DialogTitle>}
      <DialogContent dividers={!!modal.header || !!modal.actions || false}>{modal.content}</DialogContent>
      {!!modal.actions && <DialogActions>{modal.actions}</DialogActions>}
    </Dialog>
  );
});
