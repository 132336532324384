import { CalendarMonth, Delete, Edit, Hearing } from "@mui/icons-material";
import {
  Avatar,
  Badge,
  Box,
  Button,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Paper,
  Toolbar,
  Typography,
} from "@mui/material";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import { format } from "date-fns";
import { observer } from "mobx-react-lite";
import { Fragment, useEffect, useState } from "react";
import { useStore } from "../../app/stores/store";
import RecordForm from "./RecordForm";
import { useNavigate } from "react-router-dom";

interface IProps {
  id: number;
}

export default observer(function RecordDetails(props: IProps) {
  const { id } = props;
  const {
    recordStore: {
      loading,
      loadRecord,
      currentRecord,
      unsetCurrentRecord,
      deleteRecord,
      currentRecordListeningHistoryByMonth,
      currentRecordListeningHistoryByDate,
      listen,
    },
    accountStore: { isLoggedIn },
  } = useStore();

  const [deleteDialog, setDeleteDialog] = useState(false);
  const [updateForm, setUpdateForm] = useState(false);
  const [showListenHistory, setShowListenHistory] = useState(false);
  const navigate = useNavigate();

  const toggleListenHistory = () => {
    setShowListenHistory(!showListenHistory);
  };

  const handleListen = () => {
    !!currentRecord && listen(currentRecord.id);
  };

  useEffect(() => {
    loadRecord(id);

    return () => {
      unsetCurrentRecord();
    };
  }, [id, loadRecord, unsetCurrentRecord]);

  if (loading || !!!currentRecord)
    return (
      <Box sx={{ textAlign: "center" }}>
        <CircularProgress />
        <Typography component={"h1"}>Loading</Typography>
      </Box>
    );

  return (
    <>
      <Toolbar disableGutters sx={{ justifyContent: "flex-end" }}>
        <Typography variant="h5" sx={{ flexGrow: 1 }}>
          {currentRecord.title}
        </Typography>
        {isLoggedIn && !updateForm && (
          <>
            <IconButton onClick={handleListen}>
              <Badge
                color="secondary"
                badgeContent={currentRecord.listenedCount}
                max={999}
              >
                <Hearing />
              </Badge>
            </IconButton>
            <IconButton onClick={() => setUpdateForm(true)}>
              <Edit />
            </IconButton>
            <IconButton onClick={() => setDeleteDialog(true)}>
              <Delete />
            </IconButton>
          </>
        )}
      </Toolbar>
      <Divider sx={{ mb: 2 }} />

      {updateForm ? (
        <RecordForm mode={"update"} />
      ) : (
        <Box sx={{ width: "100%" }}>
          <img
            style={{ width: "100%" }}
            src={
              currentRecord.image
                ? `${process.env.REACT_APP_RESOURCE_URL}${currentRecord.image}`
                : `/record_ph.png`
            }
            alt={`Cover of ${currentRecord.title}`}
            onError={(i: any) => (i.target.src = "/record_ph.png")}
          />

          <Grid container spacing={2} alignItems="stretch">
            <Grid item xs={12} sm={6}>
              <Paper sx={{ p: 2 }} elevation={6}>
                <Typography variant="h6">Artists</Typography>

                <List>
                  {currentRecord.artists.map((artist) => {
                    return (
                      <ListItemButton
                        key={artist.id}
                        onClick={() => navigate(`/artists/${artist.id}`)}
                      >
                        <ListItemAvatar>
                          <Avatar
                            alt={`Image of ${artist.name}`}
                            src={
                              artist.image
                                ? `${process.env.REACT_APP_RESOURCE_URL}${artist.image}`
                                : `/record_ph.png`
                            }
                            onError={(i: any) =>
                              (i.target.src = "/record_ph.png")
                            }
                          />
                        </ListItemAvatar>
                        <ListItemText>{artist.name}</ListItemText>
                      </ListItemButton>
                    );
                  })}
                </List>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper sx={{ p: 2 }} elevation={6}>
                <Typography variant="h6">Release</Typography>

                <List>
                  <ListItem disableGutters>
                    <ListItemText
                      primary="Year"
                      secondary={currentRecord.year}
                    />
                  </ListItem>
                  <ListItem disableGutters>
                    <ListItemText
                      primary="Medium"
                      secondary={currentRecord.medium}
                    />
                  </ListItem>
                  <ListItem disableGutters>
                    <ListItemText
                      primary="Catalogue Number"
                      secondary={
                        currentRecord.catalogueNumber.length > 0
                          ? currentRecord.catalogueNumber
                          : "-"
                      }
                    />
                  </ListItem>
                </List>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper sx={{ p: 2 }} elevation={6}>
                <Typography variant="h6">Other</Typography>
                <List>
                  <ListItem disableGutters>
                    <ListItemText
                      primary="Comment"
                      secondary={currentRecord.comment}
                    />
                  </ListItem>
                  <ListItem disableGutters>
                    <ListItemText
                      primary="Added"
                      secondary={format(
                        currentRecord.added,
                        "dd.MM.yyyy, HH:mm"
                      )}
                    />
                  </ListItem>

                  <ListItem disableGutters>
                    <ListItemText
                      primary="Listened"
                      secondary={
                        currentRecord.listenedCount === 0 ? (
                          `never`
                        ) : (
                          <>
                            {`${
                              currentRecord.listenedCount
                            } times, last time on ${format(
                              currentRecordListeningHistoryByDate[0].onAt,
                              "dd.MM.yyyy, HH:mm"
                            )}`}
                            {` `}
                            <Link href="#" onClick={toggleListenHistory}>
                              {showListenHistory ? `Hide` : `Show`} Details
                            </Link>
                          </>
                        )
                      }
                    />
                  </ListItem>
                </List>

                <Collapse in={showListenHistory} timeout="auto" unmountOnExit>
                  <Timeline>
                    {currentRecordListeningHistoryByMonth.map((month) => {
                      return (
                        <Fragment key={month[0]}>
                          <TimelineItem position="left">
                            <TimelineSeparator>
                              <TimelineDot color="primary">
                                <CalendarMonth />
                              </TimelineDot>
                              <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent sx={{ pt: "21px", px: 2 }}>
                              {format(new Date(month[0]), "MMMM yyyy")}
                            </TimelineContent>
                          </TimelineItem>
                          {month[1].map((entry, idx) => {
                            return (
                              <TimelineItem key={idx}>
                                <TimelineSeparator>
                                  <TimelineDot />
                                  <TimelineConnector />
                                </TimelineSeparator>
                                <TimelineContent>
                                  {format(entry.onAt, "dd.MM.yyyy, HH:mm")}
                                </TimelineContent>
                              </TimelineItem>
                            );
                          })}
                        </Fragment>
                      );
                    })}
                  </Timeline>
                </Collapse>
              </Paper>
            </Grid>
          </Grid>

          <Dialog
            open={deleteDialog}
            onClose={() => setDeleteDialog(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              Really delete Record?
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                This cannot be reverted. So be sure what you're doing!
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setDeleteDialog(false)}>Cancel</Button>
              <Button onClick={() => deleteRecord(currentRecord.id)} autoFocus>
                Yes, I'm sure
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      )}
    </>
  );
});
