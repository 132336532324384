import {
  Album,
  BarChart,
  ChevronLeft,
  Groups3,
  Login,
  Logout,
  Password,
  People,
} from "@mui/icons-material";
import MuiDrawer from "@mui/material/Drawer";
import {
  Toolbar,
  IconButton,
  Divider,
  List,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  styled,
} from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";
import { drawerWidth } from "../common/styles";
import { useStore } from "../stores/store";
import LoginForm from "../../features/account/LoginForm";
import { observer } from "mobx-react-lite";
import ChangePassword from "../../features/account/ChangePassword";

const NavDrawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    background: "#7986CB",
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      // [theme.breakpoints.up("sm")]: {
      //   width: theme.spacing(7),
      // },
    }),
  },
}));

interface IProps {
  open: boolean;
  toggleOpen: () => void;
}

export default observer(function Navigation(props: IProps) {
  const { open, toggleOpen } = props;
  const {
    modalStore: { openModal },
    accountStore: { isLoggedIn, logout },
  } = useStore();

  const navigate = useNavigate();

  return (
    <NavDrawer variant="permanent" open={open}>
      <Toolbar
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          px: [1],
        }}
      >
        <IconButton onClick={toggleOpen}>
          <ChevronLeft />
        </IconButton>
      </Toolbar>
      <Divider />
      <Toolbar
        sx={{
          display: "flex",
          alignItems: "normal",
          px: [0],
          flexGrow: 1,
        }}
      >
        <List component="nav" sx={{ flex: "0 0 100%" }}>
          <ListItemButton component={NavLink} to="/">
            <ListItemIcon>
              <Album />
            </ListItemIcon>
            <ListItemText>Records</ListItemText>
          </ListItemButton>

          <ListItemButton component={NavLink} to="/artists">
            <ListItemIcon>
              <Groups3 />
            </ListItemIcon>
            <ListItemText>Artists</ListItemText>
          </ListItemButton>

          <ListItemButton component={NavLink} to="/stats">
            <ListItemIcon>
              <BarChart />
            </ListItemIcon>
            <ListItemText>Statistics</ListItemText>
          </ListItemButton>
        </List>
      </Toolbar>
      <Divider />
      <List component="nav">
        {isLoggedIn ? (
          <>
            <ListItemButton
              onClick={() =>
                openModal({
                  size: "xs",
                  header: "Change your Password",
                  content: <ChangePassword />,
                })
              }
            >
              <ListItemIcon>
                <Password />
              </ListItemIcon>
              <ListItemText>Change Password</ListItemText>
            </ListItemButton>

            <ListItemButton component={NavLink} to="/accounts">
              <ListItemIcon>
                <People />
              </ListItemIcon>
              <ListItemText>Accounts</ListItemText>
            </ListItemButton>

            <ListItemButton
              onClick={() => {
                logout();
                navigate("/");
              }}
            >
              <ListItemIcon>
                <Logout />
              </ListItemIcon>
              <ListItemText>Logout</ListItemText>
            </ListItemButton>
          </>
        ) : (
          <ListItemButton onClick={() => openModal({ content: <LoginForm /> })}>
            <ListItemIcon>
              <Login />
            </ListItemIcon>
            <ListItemText>Login</ListItemText>
          </ListItemButton>
        )}
      </List>
    </NavDrawer>
  );
});
