import {
  Box,
} from "@mui/material";
import ArtistResults from "./ArtistResults";
import RecordResults from "./RecordResults";
import SearchForm from "./SearchForm";

export default function Search() {
  return (
    <>
      <Box m={2}>
        <SearchForm />
      </Box>
      <Box>
        <RecordResults />
      </Box>
      <Box>
        <ArtistResults />
      </Box>
    </>
  );
}
