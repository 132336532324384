import { makeAutoObservable, reaction, runInAction } from "mobx";
import { toast } from "react-toastify";
import agent from "../api/agent";
import { Artist, ArtistFormValues, ArtistOption } from "../models/artist";
import { Pagination, PagingParams } from "../models/pagination";
import { store } from "./store";

export const defaultPageSize = 50;
export const defaultOrderBy:
  | "nameAsc"
  | "nameDesc"
  | "newestAsc"
  | "newestDesc"
  | "numberRecordsAsc"
  | "numberRecordsDesc" = "nameAsc";
export const defaultArtistsSearchPageSize = 10;

export default class ArtistStore {
  artistRegistry = new Map<number, Artist>();
  currentArtist?: Artist = undefined;
  loading = false;
  loadingInitial = false;
  pagination: Pagination | null = null;
  pagingParams = new PagingParams();
  predicate = new Map().set("all", true);
  artistOptions: ArtistOption[] = [];

  constructor() {
    makeAutoObservable(this);

    reaction(
      () => this.predicate.keys(),
      () => {
        this.pagingParams = new PagingParams();
        this.artistRegistry.clear();
        this.loadArtists();
      }
    );

    reaction(
      () => this.searchTerm,
      (term) => {
        this.searchPagination = null;
        this.searchPagingParams = new PagingParams();
        this.artistSearchResults.clear();

        if (term.length > 0) this.searchArtists();
      }
    );

    reaction(
      () => this.searchOrderBy,
      (term) => {
        this.searchPagination = null;
        this.searchPagingParams = new PagingParams();
        this.artistSearchResults.clear();

        this.searchArtists();
      }
    );
  }

  setLoading = (loading: boolean) => {
    this.loading = loading;
  };

  setLoadingInitial = (loading: boolean) => {
    this.loadingInitial = loading;
  };

  setPagination = (pagination: Pagination) => {
    this.pagination = pagination;
  };

  setPagingParams = (pagingParams: PagingParams) => {
    this.pagingParams = pagingParams;
  };

  setPredicate = (predicate: string, value: string) => {
    switch (predicate) {
      case "keyword":
        this.predicate.delete("keyword");
        this.predicate.set("keyword", value);
        break;
      case "orderBy":
        this.predicate.delete("orderBy");
        this.predicate.set("orderBy", value);
        break;
    }
  };

  get axiosParams() {
    const params = new URLSearchParams();
    params.append("pageNumber", this.pagingParams.pageNumber.toString());
    params.append("pageSize", defaultPageSize.toString());
    this.predicate.forEach((value, key) => {
      params.append(key, value);
    });

    return params;
  }

  get artists() {
    return Array.from(this.artistRegistry.values());
  }

  loadArtists = async () => {
    try {
      const result = await agent.Artists.list(this.axiosParams);
      result.data.forEach((artist) => {
        this.setArtist(artist);
      });
      this.setPagination(result.pagination);
      this.setLoadingInitial(false);
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
    }
  };

  private setArtist = (artist: Artist) => {
    artist.added = new Date(artist.added);

    this.artistRegistry.set(artist.id, artist);
  };

  loadArtist = async (id: number) => {
    this.loading = true;

    try {
      const result = await agent.Artists.details(id);
      this.setCurrentArtist(result);
      this.setLoading(false);
      return result;
    } catch (error) {
      console.log(error);
      this.setLoading(false);
    }
  };

  setCurrentArtist = (artist: Artist) => {
    artist.added = new Date(artist.added);

    this.currentArtist = artist;
  };

  unsetCurrentArtist = () => {
    this.currentArtist = undefined;
  };

  loadingArtistOptions = false;
  setLoadingArtistOptions = (loading: boolean) => {
    this.loadingArtistOptions = loading;
  };

  loadArtistOptions = async () => {
    this.loadingArtistOptions = true;
    try {
      const result = await agent.Artists.getOptions();
      runInAction(() => (this.artistOptions = result));
      this.setLoadingArtistOptions(false);
    } catch (error) {
      console.log(error);
      this.setLoadingArtistOptions(false);
    }
  };

  get artistOpts() {
    return this.artistOptions.map((opt) => ({
      value: opt.id,
      display: opt.name,
      discogsId: opt.discogsId,
    }));
  }

  updateArtist = async (values: ArtistFormValues) => {
    try {
      await agent.Artists.update(values);
      store.modalStore.closeModal();
      toast.success("Artist updated");
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  deleteArtist = async (id: number) => {
    try {
      await agent.Artists.delete(id);
      if (!!this.currentArtist && id === this.currentArtist.id) {
        this.unsetCurrentArtist();
      }
      runInAction(() => {
        this.artistRegistry.delete(id);
        this.artistOptions = this.artistOptions.filter((x) => x.id !== id);
        this.artistSearchResults.delete(id);
      });
      toast.success("Artist deleted");
      store.modalStore.closeModal();
    } catch (error) {
      console.log(error);
      toast.error("Could not delete artist");
    }
  };

  artistSearchResults = new Map<number, Artist>();
  searching = false;
  searchPagination: Pagination | null = null;
  searchPagingParams = new PagingParams();
  searchTerm = "";
  searchOrderBy = "";

  setSearching = (searching: boolean) => {
    this.searching = searching;
  };

  setSearchPagination = (pagination: Pagination) => {
    this.searchPagination = pagination;
  };

  setSearchPagingParams = (pagingParams: PagingParams) => {
    this.searchPagingParams = pagingParams;
  };

  setSearchTerm = (searchTerm: string) => {
    this.searchTerm = searchTerm;
  };

  setSearchOrderBy = (orderBy: string) => {
    this.searchOrderBy = orderBy;
  };

  get searchAxiosParams() {
    const params = new URLSearchParams();
    params.append("pageNumber", this.searchPagingParams.pageNumber.toString());
    params.append("pageSize", defaultArtistsSearchPageSize.toString());
    params.append("keyword", this.searchTerm);
    if (this.searchOrderBy.length > 0)
      params.append("orderBy", this.searchOrderBy);

    return params;
  }

  get searchResults() {
    return Array.from(this.artistSearchResults.values());
  }

  searchArtists = async () => {
    this.searching = true;

    try {
      const result = await agent.Artists.list(this.searchAxiosParams);
      result.data.forEach((artist) => {
        this.setArtistsSearchResult(artist);
      });
      this.setSearchPagination(result.pagination);
      this.setSearching(false);
    } catch (error) {
      console.log(error);
      this.setSearching(false);
    }
  };

  private setArtistsSearchResult = (artist: Artist) => {
    artist.added = new Date(artist.added);

    this.artistSearchResults.set(artist.id, artist);
  };
}
