import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useStore } from "../../app/stores/store";
import * as Yup from "yup";
import { TextField } from "formik-mui";
import ValidationErrors from "../../app/common/form/ValidationErrors";
import { ArtistFormValues } from "../../app/models/artist";

export default observer(function ArtistForm() {
  const [formValues, setFormValues] = useState(new ArtistFormValues());

  const {
    artistStore: { updateArtist, currentArtist },
  } = useStore();

  useEffect(() => {
    if (!!currentArtist) setFormValues(currentArtist);
  }, [currentArtist]);

  const recordValidation = Yup.object({
    name: Yup.string().required(),
    discogsId: Yup.number(),
  });

  if (!!!currentArtist)
    return (
      <Box sx={{ textAlign: "center" }}>
        <CircularProgress />
        <Typography component={"h1"}>Loading</Typography>
      </Box>
    );

  return (
    <Formik
      initialValues={{ error: null, ...formValues }}
      onSubmit={(values, { setErrors }) => {
        updateArtist(values).catch((error) => setErrors({ error: error }));
      }}
      enableReinitialize
      validationSchema={recordValidation}
    >
      {({ handleSubmit, isSubmitting, isValid, dirty, errors }) => (
        <Form onSubmit={handleSubmit} autoComplete="off">
          <Box margin={1}>
            <Field
              component={TextField}
              name="name"
              label="Name"
              variant="standard"
              fullWidth
            />
          </Box>

          <Box margin={1}>
            <Field
              component={TextField}
              name="discogsId"
              label="Discogs Database ID"
              variant="standard"
              fullWidth
            />
          </Box>
          <ErrorMessage
            name="error"
            render={() => <ValidationErrors errors={errors.error} />}
          />
          <Box sx={{ margin: 1, mt: 3, textAlign: "right" }}>
            <Button
              type="submit"
              color="primary"
              disabled={!isValid || !dirty || isSubmitting}
              variant="contained"
            >
              {`Update Artist`}
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
});
