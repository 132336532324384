import { Alert, Box } from "@mui/material";
import { useEffect, useState } from "react";

interface IProps {
  errors?: string | string[];
}
export default function ValidationErrors(props: IProps) {
  const [errors, setErrors] = useState<string[]>([]);

  useEffect(() => {
    if (props.errors) {
      if (!Array.isArray(props.errors)) setErrors([props.errors]);
      else setErrors(props.errors);
    }
  }, [props.errors]);

  return (
    <Box margin={1}>
      {errors.map((e, idx) => (
        <Alert key={idx} severity="error">{e}</Alert>
      ))}
    </Box>
  );
}
