import { useEffect, useMemo, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  CssBaseline,
  PaletteMode,
  Toolbar,
  useMediaQuery,
} from "@mui/material";

import Navigation from "./Navigation";
import TopBar from "./TopBar";
import { Box } from "@mui/system";
import { ColorModeContext } from "./ColorMode";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import RecordsList from "../../features/records/RecordsList";
import ArtistsList from "../../features/artists/ArtistsList";
import ModalComponent from "../common/modals/ModalComponent";
import { observer } from "mobx-react-lite";
import { useStore } from "../stores/store";
import { ToastContainer } from "react-toastify";
import Accounts from "../../features/account/Accounts";
import SidebarComponent from "../common/sidebars/SidebarComponent";
import ArtistSearchList from "../../features/artists/ArtistSearchList";
import RecordSearchList from "../../features/records/RecordSearchList";
import { deepOrange, indigo } from "@mui/material/colors";
import RecordStats from "../../features/records/RecordStats";

export default observer(function App() {
  const { commonStore, accountStore } = useStore();

  useEffect(() => {
    if (commonStore.token) {
      accountStore.getUser().finally(() => commonStore.setAppLoaded());
    } else {
      commonStore.setAppLoaded();
    }
  }, [commonStore, accountStore]);

  const [themeMode, setThemeMode] = useState<PaletteMode>("light");

  //check os/browser theme setting
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  useMemo(
    () =>
      setThemeMode((prevValue) =>
        prevValue !== "dark" && prefersDarkMode ? "dark" : "light"
      ),
    [prefersDarkMode]
  );

  //check stored theme setting
  const [storedMode] = useState(
    localStorage.getItem("theme") as "light" | "dark" | null
  );
  useMemo(() => {
    if (!!storedMode) setThemeMode(storedMode);
  }, [storedMode]);
  useEffect(() => {
    localStorage.setItem("theme", themeMode);
  }, [themeMode]);

  //toggle theme logic
  const toggleTheme = useMemo(
    () => ({
      toggleColorMode: () => {
        setThemeMode((prevValue) => (prevValue === "light" ? "dark" : "light"));
      },
    }),
    []
  );

  //create theme
  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: themeMode,
          primary: { main: indigo[400] },
          secondary: deepOrange,
        },
      }),
    [themeMode]
  );

  //navigation drawer
  const [navOpen, setNavOpen] = useState(false);
  const toggleNav = () => {
    setNavOpen(!navOpen);
  };

  return (
    <ColorModeContext.Provider value={toggleTheme}>
      <ThemeProvider theme={theme}>
        <ModalComponent />
        <ToastContainer position="bottom-right" theme="colored" />
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <TopBar open={navOpen} toggleOpen={toggleNav} />
          <Navigation open={navOpen} toggleOpen={toggleNav} />
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              height: "100vh",
              overflow: "auto",
              paddingX: 4,
            }}
          >
            <Toolbar />
            <Routes>
              <Route path="/" element={<RecordsList />} />
              <Route path="/records" element={<RecordsList />} />
              <Route path="/stats" element={<RecordStats />} />
              <Route path="/records/:id" element={<RecordsList />} />
              <Route path="/records/search" element={<RecordSearchList />} />
              <Route path="/artists" element={<ArtistsList />} />
              <Route path="/artists/:id" element={<ArtistsList />} />
              <Route path="/artists/search" element={<ArtistSearchList />} />

              <Route
                path="/accounts"
                element={
                  <RequireAuth>
                    <Accounts />
                  </RequireAuth>
                }
              />
            </Routes>
          </Box>

          <SidebarComponent />
        </Box>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
});

function RequireAuth({ children }: any) {
  const {
    accountStore: { isLoggedIn },
  } = useStore();

  const location = useLocation();

  if (!isLoggedIn) {
    return <Navigate to="/" state={{ from: location }} />;
  }

  return children;
}
