import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { useStore } from "../../app/stores/store";
import RecordDetails from "../records/RecordDetails";

export default observer(function RecordResults() {
  const {
    recordStore: { searching, searchResults, searchPagination },
    sidebarStore: { closeSidebar },
    modalStore: { openModal },
  } = useStore();

  const navigate = useNavigate();

  return (
    <>
      <Typography sx={{ pl: 2 }} variant="h6">
        Records
      </Typography>

      {searching ? (
        <Box display={"flex"} justifyContent={"center"} mb={2}>
          <CircularProgress size={20} />
        </Box>
      ) : searchResults.length === 0 ? (
        <Typography pl={2} variant="subtitle1" gutterBottom>
          No results
        </Typography>
      ) : (
        <>
          <List>
            {searchResults.slice(0, 9).map((record) => {
              return (
                <ListItemButton
                  key={record.id}
                  onClick={() =>
                    openModal({
                      size: "sm",
                      content: <RecordDetails id={record.id} />,
                    })
                  }
                >
                  <ListItemAvatar>
                    <Avatar
                      variant="square"
                      alt={record.title}
                      src={
                        record.image
                          ? `${process.env.REACT_APP_RESOURCE_URL}${record.image}`
                          : `/record_ph.png`
                      }
                      onError={(i: any) => (i.target.src = "/record_ph.png")}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={record.title}
                    secondary={record.artists
                      .map((artist) => artist.name)
                      .join(", ")}
                  />
                </ListItemButton>
              );
            })}
          </List>

          {!!searchPagination && searchPagination.totalItems > 10 && (
            <Box display={"flex"} justifyContent={"center"} mb={2}>
              <Button
                size="large"
                color="primary"
                variant="text"
                onClick={() => {
                  closeSidebar();
                  navigate("/records/search");
                }}
              >
                {`Browse all ${searchPagination?.totalItems} Records`}
              </Button>
            </Box>
          )}
        </>
      )}
    </>
  );
});
