import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import {
  Button,
  ButtonGroup,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Chip,
  Container,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import ArtistDetails from "../artists/ArtistDetails";
import RecordDetails from "./RecordDetails";
import CardGridPlaceholder from "../../app/layout/CardGridPlaceholder";

export default observer(function RecordStats() {
  const {
    recordStore: { loadRecordsStats, recordsStats, loadingRecordsStats },
    modalStore: { openModal },
  } = useStore();

  useEffect(() => {
    loadRecordsStats();
  }, [loadRecordsStats]);

  if (loadingRecordsStats || !!!recordsStats)
    return (
      <Container maxWidth="xl" sx={{ pt: 2 }}>
        <Grid container spacing={2}>
          <CardGridPlaceholder items={4} />
        </Grid>
      </Container>
    );

  return (
    <Container maxWidth="xl" sx={{ pt: 2 }}>
      <Grid container spacing={2}>
        <Grid xs={12} md={6} lg={3} item sx={{ display: "flex" }}>
          <Card
            elevation={12}
            square
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              flexGrow: 1,
            }}
          >
            <div>
              <CardHeader title="Stats" />
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  {`${recordsStats.recordsCount} Records from ${recordsStats.artistsCount} Artists`}
                </Typography>
                <List>
                  {recordsStats.mediumStats.map((medium, idx) => (
                    <ListItem disableGutters key={idx}>
                      <ListItemText>
                        <Chip label={medium.count} color="info" />{" "}
                        {medium.medium}
                      </ListItemText>
                    </ListItem>
                  ))}
                </List>
              </CardContent>
            </div>
            <div>
              <CardActions sx={{ justifyContent: "center" }}>
                <ButtonGroup variant="contained" size="medium">
                  <Button component={NavLink} to="/records">
                    Go to Records
                  </Button>
                  <Button component={NavLink} to="/artists">
                    Go to Artists
                  </Button>
                </ButtonGroup>
              </CardActions>
            </div>
          </Card>
        </Grid>
        <Grid item xs={12} md={6} lg={3} sx={{ display: "flex" }}>
          <Card
            elevation={12}
            square
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
            }}
          >
            <CardActionArea
              onClick={() =>
                openModal({
                  size: "sm",
                  content: <RecordDetails id={recordsStats.latestRecord.id} />,
                })
              }
            >
              <CardHeader title="Latest Record" />
              <CardMedia
                component="img"
                image={
                  recordsStats.latestRecord.image
                    ? `${process.env.REACT_APP_RESOURCE_URL}${recordsStats.latestRecord.image}`
                    : `/record_ph.png`
                }
                alt={`Cover of ${recordsStats.latestRecord.title}`}
                onError={(i: any) => (i.target.src = "/record_ph.png")}
              />
              <CardContent>
                <Typography variant="h6" color={"info"}>
                  {recordsStats.latestRecord.title}
                </Typography>
                <Typography variant="body1">
                  {recordsStats.latestRecord.artists
                    .map((artist) => artist.name)
                    .join(", ")}
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                >{`${recordsStats.latestRecord.medium}, ${recordsStats.latestRecord.year}`}</Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
        <Grid xs={12} md={6} lg={3} item sx={{ display: "flex" }}>
          <Card
            elevation={12}
            square
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
            }}
          >
            <CardActionArea
              onClick={() =>
                openModal({
                  size: "sm",
                  content: <ArtistDetails id={recordsStats.latestArtist.id} />,
                })
              }
            >
              <CardHeader title="Latest Artist" />
              <CardMedia
                component="img"
                image={
                  recordsStats.latestArtist.image
                    ? `${process.env.REACT_APP_RESOURCE_URL}${recordsStats.latestArtist.image}`
                    : `/record_ph.png`
                }
                alt={`Cover of ${recordsStats.latestArtist.name}`}
                onError={(i: any) => (i.target.src = "/record_ph.png")}
              />
              <CardContent>
                <Typography variant="h6" color={"info"}>
                  {recordsStats.latestArtist.name}
                </Typography>
                <Typography variant="body1">
                  {`${recordsStats.latestArtist.recordsCount} Records`}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
        <Grid xs={12} md={6} lg={3} item sx={{ display: "flex" }}>
          <Card
            elevation={12}
            square
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
            }}
          >
            <CardActionArea
              onClick={() =>
                openModal({
                  size: "sm",
                  content: (
                    <ArtistDetails id={recordsStats.maxRecordsArtist.id} />
                  ),
                })
              }
            >
              <CardHeader
                title={`Artist with most Records (${recordsStats.maxRecordsArtistCount})`}
              />
              <CardMedia
                component="img"
                image={
                  recordsStats.latestArtist.image
                    ? `${process.env.REACT_APP_RESOURCE_URL}${recordsStats.maxRecordsArtist.image}`
                    : `/record_ph.png`
                }
                alt={`Cover of ${recordsStats.maxRecordsArtist.name}`}
                onError={(i: any) => (i.target.src = "/record_ph.png")}
              />
              <CardContent>
                <Typography variant="h6" color={"info"}>
                  {recordsStats.maxRecordsArtist.name}
                </Typography>
                <Typography variant="body1">
                  {`${recordsStats.maxRecordsArtist.recordsCount} Records`}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
});
