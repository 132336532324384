import { Alert, Box, Button } from "@mui/material";
import { TextField } from "formik-mui";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useStore } from "../../app/stores/store";
import * as Yup from "yup";

export default function LoginForm() {
  const {
    accountStore: { login },
  } = useStore();

  const validationSchema = Yup.object({
    username: Yup.string().required(),
    password: Yup.string().required(),
  });

  return (
    <Formik
      initialValues={{ username: "", password: "", error: null }}
      onSubmit={(values, { setErrors }) =>
        login(values).catch((error) =>
          setErrors({ error: "Invalid credentials" })
        )
      }
      validationSchema={validationSchema}
    >
      {({ handleSubmit, isSubmitting, errors }) => (
        <Form onSubmit={handleSubmit} autoComplete="off">
          <Box margin={1}>
            <Field
              component={TextField}
              name="username"
              label="Username"
              variant="standard"
            />
          </Box>
          <Box margin={1}>
            <Field
              component={TextField}
              name="password"
              label="Password"
              type="password"
              variant="standard"
            />
          </Box>
          <ErrorMessage
            name="error"
            render={() => (
              <Box margin={1}>
                <Alert severity="error">{errors.error}</Alert>
              </Box>
            )}
          />
          <Box sx={{ textAlign: "right", margin: 1 }}>
            <Button
              type="submit"
              color="primary"
              disabled={isSubmitting}
              variant="contained"
            >
              Login
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
}
