import {
  Box,
  SwipeableDrawer,
  useTheme,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { useStore } from "../../stores/store";

export default observer(function SidebarComponent() {
  const theme = useTheme();
  const {
    sidebarStore: { setOpen, sidebar, closeSidebar },
  } = useStore();

  return (
    <SwipeableDrawer
      anchor="right"
      open={sidebar.open}
      onOpen={setOpen}
      onClose={closeSidebar}
      sx={{ zIndex: theme.zIndex.drawer + 1 }}
    >
      <Box sx={{ pl: 0.5, width: 350 }}>
        {sidebar.content}
      </Box>
    </SwipeableDrawer>
  );
});
