import {
  Brightness6Outlined,
  DarkModeOutlined,
  LightModeOutlined,
} from "@mui/icons-material";
import { IconButton, ListItemIcon, Menu, MenuItem } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { createContext, useContext, useState } from "react";

export const ColorModeContext = createContext({ toggleColorMode: () => {} });

export function ToggleColorMode() {
  const theme = useTheme();
  const colorMode = useContext(ColorModeContext);


  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton color="inherit" sx={{ ml: 1 }} onClick={handleClick}>
        <Brightness6Outlined />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
      >
        <MenuItem
          disabled={theme.palette.mode === "light"}
          onClick={colorMode.toggleColorMode}
        >
          <ListItemIcon>
            <LightModeOutlined />
          </ListItemIcon>
          Light
        </MenuItem>
        <MenuItem
          disabled={theme.palette.mode === "dark"}
          onClick={colorMode.toggleColorMode}
        >
          <ListItemIcon>
            <DarkModeOutlined />
          </ListItemIcon>
          Dark
        </MenuItem>
      </Menu>
    </>
  );
}
