import { SearchRounded } from "@mui/icons-material";
import { InputAdornment } from "@mui/material";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import { observer } from "mobx-react-lite";
import { useLocation, useNavigate } from "react-router-dom";
import { useStore } from "../../app/stores/store";
import Search from "./Search";
import * as Yup from "yup";
import { useTheme } from "@mui/material/styles";

interface IProps {
  openSidebar?: true;
}

export default observer(function SearchForm(props: IProps) {
  const {
    sidebarStore: { openSidebar },
    commonStore: { searchTerm, setSearchTerm },
  } = useStore();

  const validationScheme = Yup.object({
    searchTerm: Yup.string().required(""),
  });

  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Formik
      onSubmit={(values) => {
        const parent = location.pathname.replace("/search", "");
        if (parent === "/records" || parent === "/") {
          navigate("/records");
        } else if (parent === "/artists") {
          navigate("/artists");
        }

        setSearchTerm(values.searchTerm);

        props.openSidebar &&
          openSidebar({
            content: <Search />,
          });
      }}
      validationSchema={validationScheme}
      initialValues={{ searchTerm: searchTerm }}
      enableReinitialize
    >
      {({ handleSubmit, dirty, isValid }) => (
        <Form onSubmit={handleSubmit} autoComplete="off">
          <Field
            sx={{
              color: "inherit",
              '& .MuiInputBase-input' : {
                color: (props.openSidebar && theme.palette.mode) === "light" ? "white" : "inherit"
              },
              '& .MuiInputAdornment-root' : {
                color: (props.openSidebar && theme.palette.mode) === "light" ? "white" : "inherit",
              },
            }}
            fullWidth
            component={TextField}
            name="searchTerm"
            placeholder="Search..."
            error={dirty && !isValid}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchRounded
                    color={dirty && !isValid ? "error" : undefined}
                  />
                </InputAdornment>
              ),
            }}
            variant="standard"
            disabled={false}
          />
        </Form>
      )}
    </Formik>
  );
});
