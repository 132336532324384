import { Artist } from "./artist";

export interface Record {
  id: number;
  image: string;
  title: string;
  year: number;
  medium: string;
  catalogueNumber: string;
  comment: string;
  added: Date;
  artists: LinkedArtist[];
  age: number;
  discogsId: number | null;
  listenedCount: number;
  listenedToRecord: ListenedTo[];
}

export interface ListenedTo {
  onAt: Date;
}

export interface LinkedArtist {
  id: number;
  name: string;
  age: number;
  image: string;
  discogsId: number | null;
}

export class RecordFormValues {
  id?: number = undefined;
  title: string = "";
  year: number = new Date().getFullYear();
  medium: string = "";
  catalogueNumber: string = "";
  comment: string = "";
  artists: ArtistFormValues[] = [];
  discogsId: number = 0;

  constructor(record?: RecordFormValues) {
    if (record) {
      this.id = record.id;
      this.title = record.title;
      this.year = record.year;
      this.medium = record.medium;
      this.catalogueNumber = record.catalogueNumber;
      this.comment = record.comment;
      this.artists = record.artists;
      this.discogsId = record.discogsId;
    }
  }
}

export class Record implements Record {
  constructor(init?: RecordFormValues) {
    Object.assign(this, init);
  }
}

export interface ArtistFormValues {
  value: number;
  display: string;
  discogsId: number;
}

export interface DiscogsSearchResult {
  discogsId: number;
  title: string;
  year: string;
  catalogueNumber: string;
  mediumDescription: string;
  label: string;
  barcode: string;
}

export interface RecordsStats {
  recordsCount: number;
  mediumStats: MediumStats[];
  artistsCount: number;
  latestRecord: Record;
  latestArtist: Artist;
  maxRecordsArtistCount: number;
  maxRecordsArtist: Artist;
}

export interface MediumStats {
  medium: string;
  count: number;
}