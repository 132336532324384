import { Delete, Edit, Hearing } from "@mui/icons-material";
import {
  Badge,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { toast } from "react-toastify";
import { OverflowingCardHeader } from "../../app/layout/CustomizedMuiComponents";
import { Record } from "../../app/models/record";
import { useStore } from "../../app/stores/store";
import RecordDetails from "./RecordDetails";
import RecordForm from "./RecordForm";

interface IProps {
  record: Record;
}

export default function RecordCard(props: IProps) {
  const {
    modalStore: { openModal },
    recordStore: { deleteRecord, loadRecord, listen },
    accountStore: { isLoggedIn },
  } = useStore();
  const { record } = props;
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [listenCounter, setListenCounter] = useState(
    props.record.listenedCount
  );

  const handleShow = () => {
    openModal({ size: "sm", content: <RecordDetails id={record.id} /> });
  };

  const handleUpdate = () => {
    loadRecord(record.id).then((record) =>
      !!record
        ? openModal({ size: "sm", content: <RecordForm mode="update" /> })
        : toast.error("Could not load record")
    );
  };

  const handleListen = () => {
    listen(record.id).then(
      (success) => success && setListenCounter((prev) => prev + 1)
    );
  };

  return (
    <>
      <Card elevation={12} square>
        <CardActionArea onClick={handleShow}>
          <OverflowingCardHeader
            title={record.title}
            titleTypographyProps={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            subheader={record.artists.map((artist) => artist.name).join(", ")}
            subheaderTypographyProps={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          />

          <CardMedia
            component="img"
            image={
              record.image
                ? `${process.env.REACT_APP_RESOURCE_URL}${record.image}`
                : `/record_ph.png`
            }
            alt={`Cover of ${record.title}`}
            onError={(i: any) => (i.target.src = "/record_ph.png")}
          />

          <CardContent>
            <Typography
              variant="body2"
              color="text.secondary"
            >{`${record.medium}, ${record.year}`}</Typography>
          </CardContent>
        </CardActionArea>
        <CardActions sx={{ justifyContent: "flex-end" }}>
          {isLoggedIn ? (
            <>
              <IconButton onClick={handleListen}>
                <Badge color="secondary" badgeContent={listenCounter} max={999}>
                  <Hearing />
                </Badge>
              </IconButton>
              <IconButton onClick={handleUpdate}>
                <Edit />
              </IconButton>
              <IconButton onClick={() => setDeleteDialog(true)}>
                <Delete />
              </IconButton>
            </>
          ) : (
            <Badge
              sx={{ marginY: 1, marginX: 2 }}
              color="secondary"
              badgeContent={listenCounter}
              max={999}
            >
              <Hearing />
            </Badge>
          )}
        </CardActions>
      </Card>

      <Dialog
        open={deleteDialog}
        onClose={() => setDeleteDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Really delete Record?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This cannot be reverted. So be sure what you're doing!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialog(false)}>Cancel</Button>
          <Button onClick={() => deleteRecord(record.id)} autoFocus>
            Yes, I'm sure
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
