export interface User {
  username: string;
  token: string;
}

// export interface CreateAccountFormValues {
//   username: string;
//   password: string;
//   roles: string[];
// }

// export interface UpdateAccountFormValues {
//   id: number;
//   username: string;
//   password: string;
//   roles: string[];
// }

export class AccountFormValues {
  id?: number = undefined;
  username: string = "";
  password: string = "";
  roles: string[] = [];

  constructor(init?: UserAccount) {
    if (init) {
      this.id = init.id;
      this.username = init.username;
      this.roles = init.roles;
    }
  }
}

export interface PasswordFormValues {
  password: string;
  confirmation: string;
}

export interface LoginFormValues {
  username: string;
  password: string;
}

export interface UserAccount {
  id: number;
  username: string;
  lastActive?: Date;
  roles: string[];
}
