import { Card, CardContent, CardHeader, Grid, Skeleton } from "@mui/material";

interface IProps {
  items: number;
}

export default function CardGridPlaceholder(props: IProps) {
  const { items } = props;
  return (
    <>
      {Array.from(Array(items).keys()).map((idx) => {
        return (
          <Grid item key={idx} xs={12} md={4} xl={3}>
            <Card>
              <CardHeader
                avatar={
                  <Skeleton
                    animation="wave"
                    variant="circular"
                    width={40}
                    height={40}
                  />
                }
                title={
                  <Skeleton
                    animation="wave"
                    height={10}
                    width="80%"
                    style={{ marginBottom: 6 }}
                  />
                }
                subheader={
                  <Skeleton animation="wave" height={10} width="40%" />
                }
              />
              <Skeleton
                sx={{ height: 190 }}
                animation="wave"
                variant="rectangular"
              />
              <CardContent>
                <>
                  <Skeleton
                    animation="wave"
                    height={10}
                    style={{ marginBottom: 6 }}
                  />
                  <Skeleton animation="wave" height={10} width="80%" />
                </>
              </CardContent>
            </Card>
          </Grid>
        );
      })}
    </>
  );
}
