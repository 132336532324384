import { Delete, Edit } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Paper,
  Toolbar,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useStore } from "../../app/stores/store";
import ArtistForm from "./ArtistForm";

interface IProps {
  id: number;
}

export default observer(function ArtistDetails(props: IProps) {
  const { id } = props;
  const {
    artistStore: {
      loading,
      loadArtist,
      currentArtist,
      unsetCurrentArtist,
      deleteArtist,
    },
    accountStore: { isLoggedIn },
  } = useStore();

  const [updateForm, setUpdateForm] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    loadArtist(id);

    return () => {
      unsetCurrentArtist();
    };
  }, [id, loadArtist, unsetCurrentArtist]);

  if (loading || !!!currentArtist)
    return (
      <Box sx={{ textAlign: "center" }}>
        <CircularProgress />
        <Typography component={"h1"}>Loading</Typography>
      </Box>
    );

  return (
    <>
      <Toolbar disableGutters sx={{ justifyContent: "flex-end" }}>
        <Typography variant="h5" sx={{ flexGrow: 1 }}>
          {currentArtist.name}
        </Typography>
        {isLoggedIn && !updateForm && (
          <>
            <IconButton onClick={() => setUpdateForm(true)}>
              <Edit />
            </IconButton>
            <IconButton onClick={() => setDeleteDialog(true)}>
              <Delete />
            </IconButton>
          </>
        )}
      </Toolbar>
      <Divider sx={{ mb: 2 }} />

      {updateForm ? (
        <ArtistForm />
      ) : (
        <Box sx={{ width: "100%" }}>
          <img
            style={{ width: "100%" }}
            src={
              currentArtist.image
                ? `${process.env.REACT_APP_RESOURCE_URL}${currentArtist.image}`
                : `/record_ph.png`
            }
            alt={`${currentArtist.name}`}
            onError={(i: any) => (i.target.src = "/record_ph.png")}
          />

          <Grid container spacing={2} alignItems="stretch">
            <Grid item xs={12}>
              <Paper sx={{ p: 2 }} elevation={6}>
                <Typography variant="h6">
                  {" "}
                  {currentArtist.recordsCount} Records
                </Typography>

                <List>
                  {currentArtist.records.map((record) => {
                    return (
                      <ListItemButton
                        key={record.id}
                        onClick={() => navigate(`/records/${record.id}`)}
                      >
                        <ListItemAvatar sx={{ mr: 1 }}>
                          <Avatar
                            sx={{ width: 56, height: 56 }}
                            variant="square"
                            alt={record.title}
                            src={
                              record.image
                                ? `${process.env.REACT_APP_RESOURCE_URL}${record.image}`
                                : `/record_ph.png`
                            }
                            onError={(i: any) =>
                              (i.target.src = "/record_ph.png")
                            }
                          />
                        </ListItemAvatar>
                        <ListItemText
                          primary={record.title}
                          secondary={`${record.medium}, ${record.year}, ${record.catalogueNumber}`}
                        />
                      </ListItemButton>
                    );
                  })}
                </List>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper sx={{ p: 2 }} elevation={6}>
                <Typography variant="h6">Other</Typography>
                <List>
                  <ListItem disableGutters>
                    <ListItemText
                      primary="Added"
                      secondary={format(
                        currentArtist.added,
                        "dd.MM.yyyy, HH:mm"
                      )}
                    />
                  </ListItem>
                </List>
              </Paper>
            </Grid>
          </Grid>

          <Dialog
            open={deleteDialog}
            onClose={() => setDeleteDialog(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              Really delete Artist?
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                This cannot be reverted. So be sure what you're doing!
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setDeleteDialog(false)}>Cancel</Button>
              <Button onClick={() => deleteArtist(currentArtist.id)} autoFocus>
                Yes, I'm sure
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      )}
    </>
  );
});
