import { Box, Button, MenuItem } from "@mui/material";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import * as Yup from "yup";
import { useStore } from "../../app/stores/store";
import { TextField } from "formik-mui";
import { roleOptions } from "../../app/common/options/roleOptions";
import ValidationErrors from "../../app/common/form/ValidationErrors";
import { AccountFormValues, UserAccount } from "../../app/models/account";
import { useEffect, useState } from "react";

interface IProps {
  account?: UserAccount;
}

export default observer(function AccountForm(props: IProps) {
  const [formValues, setFormValues] = useState(new AccountFormValues());
  const {
    accountStore: { createAccount, updateAccount },
  } = useStore();

  const createValidationSchema = Yup.object({
    username: Yup.string().required(),
    password: Yup.string().required(),
    roles: Yup.array(Yup.string()).min(1),
  });

  const updateValidationSchema = Yup.object({
    username: Yup.string().required(),
    roles: Yup.array(Yup.string()).min(1),
  });

  useEffect(() => {
    if (!!props.account) {
      setFormValues(new AccountFormValues(props.account));
    }
  }, [props.account]);

  return (
    <Formik
      initialValues={{...formValues, error: null}}
      validationSchema={
        !!props.account ? updateValidationSchema : createValidationSchema
      }
      enableReinitialize
      onSubmit={(values, { setErrors, setSubmitting }) => {
        if (!!values.id)
          updateAccount(values).catch((error) => {setErrors({ error: error }); setSubmitting(false);});
        else
          createAccount(values).catch((error) => {setErrors({ error: error }); setSubmitting(false); });
      }}
    >
      {({ handleSubmit, isSubmitting, errors, isValid, dirty }) => (
        <Form onSubmit={handleSubmit} autoComplete="off">
          <Box margin={1}>
            <Field
              component={TextField}
              name="username"
              label="Username"
              variant="standard"
              fullWidth
            />
          </Box>
          <Box margin={1}>
            <Field
              component={TextField}
              name="password"
              label="Password"
              variant="standard"
              type="password"
              fullWidth
            />
          </Box>
          <Box margin={1}>
            <Field
              fullWidth
              select
              SelectProps={{ multiple: true }}
              component={TextField}
              name="roles"
              label="Roles"
              type="text"
              variant="standard"
            >
              {roleOptions.map((opt) => (
                <MenuItem key={opt.value} value={opt.value}>
                  {opt.text}
                </MenuItem>
              ))}
            </Field>
          </Box>
          <ErrorMessage
            name="error"
            render={() => <ValidationErrors errors={errors.error} />}
          />
          <Box sx={{ margin: 1, mt: 3, textAlign: "right" }}>
            <Button
              type="submit"
              color="primary"
              disabled={!isValid || !dirty || isSubmitting}
              variant="contained"
            >
              {!!props.account ? `Update Account` : `Create Account`}
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
});
