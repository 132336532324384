import { Box, Button } from "@mui/material";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import * as Yup from "yup";
import { useStore } from "../../app/stores/store";
import { TextField } from "formik-mui";
import ValidationErrors from "../../app/common/form/ValidationErrors";

export default observer(function ChangePassword() {
  const {
    accountStore: { updatePassword },
  } = useStore();

  const validationSchema = Yup.object({
    password: Yup.string().required(),
    confirmation: Yup.string()
      .required()
      .oneOf([Yup.ref("password")]),
  });

  return (
    <Formik
      initialValues={{ password: "", confirmation: "", error: null }}
      validationSchema={validationSchema}
      onSubmit={(values, { setErrors }) =>
        updatePassword(values).catch((error) => setErrors({ error: error }))
      }
    >
      {({ handleSubmit, isSubmitting, isValid, dirty, errors }) => (
        <Form onSubmit={handleSubmit} autoComplete="off">
          <Box margin={1}>
            <Field
              fullWidth
              component={TextField}
              name="password"
              label="Password"
              type="password"
              variant="standard"
            />
          </Box>
          <Box margin={1}>
            <Field
              fullWidth
              component={TextField}
              name="confirmation"
              label="Enter password again"
              type="password"
              variant="standard"
            />
          </Box>
          <ErrorMessage
            name="error"
            render={() => <ValidationErrors errors={errors.error} />}
          />
          <Box sx={{ m: 1, mt: 3, textAlign: "right" }}>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={isSubmitting || !dirty || !isValid}
            >
              Update Password
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
});
