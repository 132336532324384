import {
  Button,
  Container,
  Grid,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import CardGridPlaceholder from "../../app/layout/CardGridPlaceholder";
import { useStore } from "../../app/stores/store";
import InfiniteScroll from "react-infinite-scroller";
import { PagingParams } from "../../app/models/pagination";
import RecordCard from "./RecordCard";
import { Add, Sort } from "@mui/icons-material";
import AddRecord from "./AddRecord";
import { useParams } from "react-router-dom";
import RecordDetails from "./RecordDetails";

export default observer(function RecordsList() {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const { id } = useParams();

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const {
    recordStore: {
      loadingInitial,
      setLoadingInitial,
      records,
      loadRecords,
      setPagingParams,
      pagination,
      predicate,
      setPredicate,
    },
    modalStore: { openModal },
    accountStore: { isLoggedIn },
  } = useStore();
  const [loadingNext, setLoadingNext] = useState(false);

  function handleGetNext() {
    setLoadingNext(true);
    setPagingParams(new PagingParams(pagination!.currentPage + 1));
    loadRecords().then(() => setLoadingNext(false));
  }

  const handleSorting = (
    sorting: "newestAsc" | "newestDesc" | "titleAsc" | "titleDesc"
  ) => {
    setLoadingInitial(true);
    setPredicate("orderBy", sorting);
  };

  useEffect(() => {
    setLoadingInitial(true);
    loadRecords();
  }, [loadRecords, setLoadingInitial]);

  useEffect(() => {
    if (id) {
      openModal({ size: "sm", content: <RecordDetails id={parseInt(id)} /> });
    }
  }, [id, openModal]);

  if (loadingInitial && !loadingNext)
    return (
      <Container maxWidth="xl">
        <Grid container spacing={4}>
          <CardGridPlaceholder items={20} />
        </Grid>
      </Container>
    );

  return (
    <>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} color="primary">
            {!!pagination && `Displaying ${Math.min(pagination.currentPage * pagination.itemsPerPage, pagination.totalItems)} of ${pagination.totalItems} Records`}
          </Typography>
          {isLoggedIn && (
            <Button
              sx={{ mr: 1 }}
              variant="outlined"
              disableElevation
              startIcon={<Add />}
              onClick={() =>
                openModal({
                  size: "md",
                  header: "Add Record",
                  content: <AddRecord />,
                })
              }
            >
              Add Record
            </Button>
          )}
          <Button
            variant="outlined"
            disableElevation
            startIcon={<Sort />}
            onClick={handleClick}
          >
            Sort by
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            onClick={handleClose}
          >
            <MenuItem
              disabled={
                !predicate.has("orderBy") ||
                predicate.get("orderBy") === "titleAsc"
              }
              onClick={() => handleSorting("titleAsc")}
            >
              Title asc
            </MenuItem>
            <MenuItem
              disabled={predicate.get("orderBy") === "titleDesc"}
              onClick={() => handleSorting("titleDesc")}
            >
              Title desc
            </MenuItem>
            <MenuItem
              disabled={predicate.get("orderBy") === "newestAsc"}
              onClick={() => handleSorting("newestAsc")}
            >
              Added asc
            </MenuItem>
            <MenuItem
              disabled={predicate.get("orderBy") === "newestDesc"}
              onClick={() => handleSorting("newestDesc")}
            >
              Added desc
            </MenuItem>
          </Menu>
        </Toolbar>
      </Container>
      <InfiniteScroll
        style={{ justifyContent: "center", display: "flex" }}
        pageStart={0}
        loadMore={handleGetNext}
        hasMore={
          !loadingNext &&
          !!pagination &&
          pagination.currentPage < pagination.totalPages
        }
        useWindow={false}
      >
        <Grid container maxWidth={"xl"} spacing={4}>
          {records.map((record) => {
            return (
              <Grid item key={record.id} xs={12} md={4} xl={3}>
                <RecordCard record={record} />
              </Grid>
            );
          })}
          {loadingNext && <CardGridPlaceholder items={5} />}
        </Grid>
      </InfiniteScroll>
    </>
  );
});
