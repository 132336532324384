import { Add } from "@mui/icons-material";
import { Button, Container, Grid, Toolbar } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import CardGridPlaceholder from "../../app/layout/CardGridPlaceholder";
import { PagingParams } from "../../app/models/pagination";
import { useStore } from "../../app/stores/store";
import AccountCard from "./AccountCard";
import AccountForm from "./AccountForm";

export default observer(function Accounts() {
  const {
    accountStore: {
      loadingInitial,
      setLoadingInitial,
      accounts,
      loadUserAccounts,
      setPagingParams,
      pagination,
      isLoggedIn,
    },
    modalStore: { openModal },
  } = useStore();
  const [loadingNext, setLoadingNext] = useState(false);

  function handleGetNext() {
    setLoadingNext(true);
    setPagingParams(new PagingParams(pagination!.currentPage + 1));
    loadUserAccounts().then(() => setLoadingNext(false));
  }

  useEffect(() => {
    setLoadingInitial(true);
    loadUserAccounts().then(() => setLoadingInitial(false));
  }, [loadUserAccounts, setLoadingInitial]);

  if (loadingInitial && !loadingNext)
    return (
      <Container maxWidth="xl">
        <Grid container spacing={4}>
          <CardGridPlaceholder items={20} />
        </Grid>
      </Container>
    );

  return (
    <>
      <Container maxWidth="xl">
        <Toolbar disableGutters sx={{ justifyContent: "flex-end" }}>
          {isLoggedIn && (
            <Button
              sx={{ mr: 1 }}
              variant="outlined"
              disableElevation
              startIcon={<Add />}
              onClick={() =>
                openModal({
                  size: "xs",
                  header: "Create new Account",
                  content: <AccountForm />,
                })
              }
            >
              Add Account
            </Button>
          )}
        </Toolbar>
      </Container>
      <InfiniteScroll
        style={{ justifyContent: "center", display: "flex" }}
        pageStart={0}
        loadMore={handleGetNext}
        hasMore={
          !loadingNext &&
          !!pagination &&
          pagination.currentPage < pagination.totalPages
        }
        useWindow={false}
      >
        <Grid container maxWidth={"xl"} spacing={4}>
          {accounts.map((account) => {
            return (
              <Grid item key={account.id} xs={12} md={4} xl={3}>
                <AccountCard account={account} />
              </Grid>
            );
          })}
          {loadingNext && <CardGridPlaceholder items={5} />}
        </Grid>
      </InfiniteScroll>
    </>
  );
});
