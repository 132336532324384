import { makeAutoObservable } from "mobx";

interface Sidebar {
  open: boolean;
  content: any;
}

interface SidebarOptions {
  content: any;
}

export default class SidebarStore {
  sidebar: Sidebar = {
    open: false,
    content: null,
  };

  constructor() {
    makeAutoObservable(this);
  }

  private resetStore = () => {
    this.sidebar = {
      open: false,
      content: null,
    };
  };

  setOpen = () => {
    this.sidebar.open = true;
  }

  openSidebar = (options: SidebarOptions) => {
    this.sidebar.open = true;
    this.sidebar.content = options.content;
  };

  closeSidebar = () => {
    this.resetStore();
  };
}
