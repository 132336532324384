import {
  Button,
  Container,
  Grid,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import CardGridPlaceholder from "../../app/layout/CardGridPlaceholder";
import { useStore } from "../../app/stores/store";
import InfiniteScroll from "react-infinite-scroller";
import { PagingParams } from "../../app/models/pagination";
import RecordCard from "./RecordCard";
import { Sort } from "@mui/icons-material";

export default observer(function RecordSearchList() {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const {
    recordStore: {
      searchResults,
      searchRecords,
      setSearchPagingParams: setPagingParams,
      searchPagination: pagination,
      setSearchOrderBy,
      searchOrderBy,
    },
  } = useStore();
  const [loadingNext, setLoadingNext] = useState(false);

  function handleGetNext() {
    setLoadingNext(true);
    setPagingParams(new PagingParams(pagination!.currentPage + 1));
    searchRecords().then(() => setLoadingNext(false));
  }

  const handleSorting = (
    sorting: "newestAsc" | "newestDesc" | "titleAsc" | "titleDesc"
  ) => {
    setSearchOrderBy(sorting);
  };

  return (
    <>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1 }}
            color="primary"
          >
            {!!pagination &&
              `Displaying ${
                Math.min(pagination.currentPage * pagination.itemsPerPage, pagination.totalItems)
              } of ${pagination.totalItems} Searchresults`}
          </Typography>
          <Button
            variant="outlined"
            disableElevation
            startIcon={<Sort />}
            onClick={handleClick}
          >
            Sort by
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            onClick={handleClose}
          >
            <MenuItem
              disabled={searchOrderBy === "" || searchOrderBy === "titleAsc"}
              onClick={() => handleSorting("titleAsc")}
            >
              Title asc
            </MenuItem>
            <MenuItem
              disabled={searchOrderBy === "titleDesc"}
              onClick={() => handleSorting("titleDesc")}
            >
              Title desc
            </MenuItem>
            <MenuItem
              disabled={searchOrderBy === "newestAsc"}
              onClick={() => handleSorting("newestAsc")}
            >
              Added asc
            </MenuItem>
            <MenuItem
              disabled={searchOrderBy === "newestDesc"}
              onClick={() => handleSorting("newestDesc")}
            >
              Added desc
            </MenuItem>
          </Menu>
        </Toolbar>
      </Container>
      <InfiniteScroll
        style={{ justifyContent: "center", display: "flex" }}
        pageStart={0}
        loadMore={handleGetNext}
        hasMore={
          !loadingNext &&
          !!pagination &&
          pagination.currentPage < pagination.totalPages
        }
        useWindow={false}
      >
        <Grid container maxWidth={"xl"} spacing={4}>
          {searchResults.map((record) => {
            return (
              <Grid item key={record.id} xs={12} md={4} xl={3}>
                <RecordCard record={record} />
              </Grid>
            );
          })}
          {loadingNext && <CardGridPlaceholder items={5} />}
        </Grid>
      </InfiniteScroll>
    </>
  );
});
