import { Check, Search } from "@mui/icons-material";
import {
  Alert,
  AlertTitle,
  Box,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import { InputBase } from "formik-mui";
import { observer } from "mobx-react-lite";
import { Fragment, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { PagingParams } from "../../app/models/pagination";
import { Record } from "../../app/models/record";
import { useStore } from "../../app/stores/store";
import RecordForm from "./RecordForm";

export default observer(function AddRecord() {
  const [discogsRelease, setDiscogsRelease] = useState<Record | undefined>(
    undefined
  );

  const {
    recordStore: {
      discogsSearching,
      searchDiscogs,
      setDiscogsSearchTerm,
      discogsResults,
      discogsPagination: pagination,
      setDiscogsPagingParams: setPagingParams,
      loadDiscogsRelease,
      resetDiscogsSearch,
    },
  } = useStore();

  const searchSubmitted = Boolean(pagination);

  const [loadingNext, setLoadingNext] = useState(false);
  const handleGetNext = () => {
    setLoadingNext(true);
    setPagingParams(new PagingParams(pagination!.currentPage + 1));
    searchDiscogs().then(() => setLoadingNext(false));
  };

  useEffect(() => {
    return () => {
      resetDiscogsSearch();
    };
  }, [resetDiscogsSearch]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={5}>
        <Paper square sx={{ p: 1 }}>
          <Formik
            initialValues={{ searchTerm: "" }}
            onSubmit={(values) => setDiscogsSearchTerm(values.searchTerm)}
          >
            {({ handleSubmit, dirty, isValid }) => (
              <Form onSubmit={handleSubmit} autoComplete="off">
                <Field
                  required
                  fullWidth
                  component={InputBase}
                  name="searchTerm"
                  placeholder="Search Discogs"
                  disabled={false}
                  endAdornment={
                    discogsSearching && !loadingNext ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      <IconButton type="submit" disabled={!dirty || !isValid}>
                        <Search />
                      </IconButton>
                    )
                  }
                />
              </Form>
            )}
          </Formik>
        </Paper>

        {searchSubmitted && (
          <Box sx={{ height: 400, overflowY: "scroll", overflowX: "hidden" }}>
            {discogsResults.length === 0 ? (
              <Alert severity="info">
                <AlertTitle>No Results</AlertTitle>
                Please modify your search and try again!
              </Alert>
            ) : (
              <InfiniteScroll
                pageStart={0}
                loadMore={handleGetNext}
                hasMore={
                  !loadingNext &&
                  !!pagination &&
                  pagination.currentPage < pagination.totalPages
                }
                useWindow={false}
              >
                <List
                  sx={{
                    width: "100%",
                    bgcolor: "background.paper",
                    position: "relative",
                  }}
                >
                  {discogsResults.map((result) => (
                    <Fragment key={result.discogsId}>
                      <ListItem
                        secondaryAction={
                          <IconButton
                            onClick={() =>
                              loadDiscogsRelease(result.discogsId).then(
                                (release) => setDiscogsRelease(release)
                              )
                            }
                            edge="end"
                          >
                            <Check fontSize="medium" />
                          </IconButton>
                        }
                      >
                        <ListItemText
                          primary={result.title}
                          secondary={`${result.year}, ${result.catalogueNumber}, ${result.mediumDescription}`}
                        ></ListItemText>
                      </ListItem>
                      <Divider component="li" />
                    </Fragment>
                  ))}
                </List>
              </InfiniteScroll>
            )}
          </Box>
        )}
      </Grid>
      <Grid item xs={12} md={7}>
        <RecordForm mode={"create"} discogsRelease={discogsRelease} />
      </Grid>
    </Grid>
  );
});
