import { Breakpoint } from "@mui/material";
import { makeAutoObservable } from "mobx";

interface Modal {
  open: boolean;
  header?: any;
  content: any;
  actions?: any;
  size?: Breakpoint | false;
}

interface ModalOptions {
  header?: any;
  content: any;
  actions?: any;
  size?: Breakpoint | false;
}

export default class ModalStore {
  modal: Modal = {
    open: false,
    header: null,
    content: null,
    actions: null,
    size: undefined,
  };

  constructor() {
    makeAutoObservable(this);
  }

  private resetStore = () => {
    this.modal = {
      open: false,
      header: null,
      content: null,
      actions: null,
      size: undefined,
    };
  };

  openModal = (options: ModalOptions) => {
    this.modal.open = true;
    this.modal.header = options.header ?? null;
    this.modal.content = options.content;
    this.modal.actions = options.actions ?? null;
    this.modal.size = options.size;
  };

  closeModal = () => {
    this.resetStore();
  };
}
