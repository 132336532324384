import axios, { AxiosError, AxiosResponse } from "axios";
import { router } from "../..";
import {
  AccountFormValues,
  LoginFormValues,
  PasswordFormValues,
  User,
  UserAccount,
} from "../models/account";
import { Artist, ArtistFormValues, ArtistOption } from "../models/artist";
import { PaginatedResult } from "../models/pagination";
import {
  Record,
  DiscogsSearchResult,
  RecordFormValues,
  RecordsStats,
} from "../models/record";
import { store } from "../stores/store";
import { toast } from "react-toastify";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

axios.interceptors.request.use((config) => {
  const token = store.commonStore.token;
  if (token && config.headers) config.headers.Authorization = `Bearer ${token}`;
  return config;
});

axios.interceptors.response.use(
  async (response) => {
    const pagination = response.headers["pagination"];
    if (pagination) {
      response.data = new PaginatedResult(
        response.data,
        JSON.parse(pagination)
      );
      return response as AxiosResponse<PaginatedResult<any>>;
    }
    return response;
  },
  (error: AxiosError) => {
    const { data, status, config } = error.response as AxiosResponse;
    switch (status) {
      case 400:
        if (config.method === "get" && data.errors.hasOwnProperty("id")) {
          router.navigate("/not-found");
        }
        if (data.errors) {
          const modalStateErrors = [];
          for (const key in data.errors) {
            if (data.errors[key]) {
              modalStateErrors.push(data.errors[key]);
            }
          }
          throw modalStateErrors.flat();
        } else {
          const modalStateErrors = [];
          modalStateErrors.push(data);
          throw modalStateErrors.flat();
        }
      case 401:
        toast.error("unauthorized");
        break;
      case 403:
        toast.error("forbidden");
        break;
      case 404:
        router.navigate("/not-found");
        break;
      case 500:
        store.commonStore.setServerError(data);
        router.navigate("/server-error");
        toast.error("Server error");
        break;
      case 503:
        toast.error(data);
        break;
    }
    return Promise.reject(error);
  }
);

const requests = {
  get: <T>(url: string) => axios.get<T>(url).then(responseBody),
  post: <T>(url: string, body: {}) =>
    axios.post<T>(url, body).then(responseBody),
  put: <T>(url: string, body: {}) => axios.put<T>(url, body).then(responseBody),
  delete: <T>(url: string) => axios.delete<T>(url).then(responseBody),
};

const Records = {
  list: (params: URLSearchParams) =>
    axios
      .get<PaginatedResult<Record[]>>("/records", { params })
      .then(responseBody),
  details: (id: number) => requests.get<Record>(`/records/${id}`),
  create: (record: RecordFormValues) =>
    requests.post<number>(`/records`, record),
  update: (record: RecordFormValues) =>
    requests.put<void>(`/records/${record.id}`, record),
  delete: (id: number) => requests.delete<void>(`/records/${id}`),
  searchDiscogs: (params: URLSearchParams) =>
    axios
      .get<PaginatedResult<DiscogsSearchResult[]>>(`/records/search`, {
        params,
      })
      .then(responseBody),
  getDiscogsRecord: (id: number) =>
    requests.get<Record>(`/records/discogs/${id}`),
  listen: (id: number) => requests.post<void>(`/records/${id}/listen`, {}),
  loadStats: () => requests.get<RecordsStats>(`/records/stats`)
};

const Artists = {
  list: (params: URLSearchParams) =>
    axios
      .get<PaginatedResult<Artist[]>>("/artists", { params })
      .then(responseBody),
  details: (id: number) => requests.get<Artist>(`/artists/${id}`),
  update: (artist: ArtistFormValues) =>
    requests.put<void>(`/artists/${artist.id}`, artist),
  delete: (id: number) => requests.delete<void>(`/artists/${id}`),
  getOptions: () => requests.get<ArtistOption[]>(`/artists/options`),
};

const Account = {
  login: (values: LoginFormValues) =>
    requests.post<User>(`/account/login`, values),
  register: (values: AccountFormValues) =>
    requests.post<User>("/account/register", values),
  update: (id: number, values: AccountFormValues) =>
    requests.put<void>(`/account/${id}`, values),
  delete: (id: number) => requests.delete<void>(`/account/${id}`),
  changePassword: (values: PasswordFormValues) =>
    requests.post<void>("/account/changePassword", values),
  current: () => requests.get<User>("/account"),
  list: (params: URLSearchParams) =>
    axios
      .get<PaginatedResult<UserAccount[]>>("/account/list", { params })
      .then(responseBody),
};

const agent = {
  Records,
  Artists,
  Account,
};

export default agent;
