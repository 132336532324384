import {
  AutocompleteRenderInputParams,
  Box,
  Button,
  CircularProgress,
  MenuItem,
  Typography,
} from "@mui/material";
import MuiTextField from "@mui/material/TextField";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { Record, RecordFormValues } from "../../app/models/record";
import { useStore } from "../../app/stores/store";
import * as Yup from "yup";
import { Autocomplete, TextField } from "formik-mui";
import { mediumOptions } from "../../app/common/options/recordOptions";
import ValidationErrors from "../../app/common/form/ValidationErrors";

const range = Array.from(
  { length: new Date().getFullYear() + 1 - 1950 },
  (v, k) => k + 1950
).sort((a, b) => (a < b ? 1 : 0));

interface IProps {
  mode: "create" | "update";
  discogsRelease?: Record;
}

export default observer(function RecordForm(props: IProps) {
  const [formValues, setFormValues] = useState(new RecordFormValues());

  const {
    recordStore: { createRecord, updateRecord, currentRecord },
    artistStore: { loadArtistOptions, loadingArtistOptions, artistOpts },
  } = useStore();

  useEffect(() => {
    if (props.mode === "update" && !!currentRecord) {
      const fv: RecordFormValues = {
        ...currentRecord,
        discogsId: currentRecord.discogsId ? currentRecord.discogsId : 0,
        artists: currentRecord.artists.map((artist) => ({
          value: artist.id,
          display: artist.name,
          discogsId: artist.discogsId ? artist.discogsId : 0,
        })),
      };

      setFormValues(fv);
    }
  }, [props.mode, currentRecord]);

  useEffect(() => {
    if (!!props.discogsRelease) {
      setFormValues({
        title: props.discogsRelease.title,
        year: props.discogsRelease.year,
        medium: props.discogsRelease.medium,
        catalogueNumber: props.discogsRelease.catalogueNumber,
        comment: props.discogsRelease.comment,
        discogsId: props.discogsRelease.discogsId,
        artists: props.discogsRelease.artists.map((artist) => ({
          value: artist.id,
          display: artist.name,
          discogsId: artist.discogsId,
        })),
      } as RecordFormValues);
    }
  }, [props.discogsRelease]);

  useEffect(() => {
    loadArtistOptions();
  }, [loadArtistOptions]);

  const recordValidation = Yup.object({
    title: Yup.string().required(),
    year: Yup.number().required(),
    medium: Yup.string().required(),
    artists: Yup.array()
      .of(
        Yup.object().shape({
          value: Yup.number(),
          display: Yup.string(),
          discogsId: Yup.number(),
        })
      )
      .min(1, "Artists is required")
  });

  if (loadingArtistOptions || (props.mode === "update" && !!!currentRecord))
    return (
      <Box sx={{ textAlign: "center" }}>
        <CircularProgress />
        <Typography component={"h1"}>Loading</Typography>
      </Box>
    );

  return (
    <Formik
      initialValues={{ error: null, ...formValues }}
      onSubmit={(values, { setErrors }) => {
        if (!!values.id) {
          updateRecord(values).catch((error) => setErrors({ error: error }));
        } else {
          createRecord(values).catch((error) => setErrors({ error: error }));
        }
      }}
      enableReinitialize
      validationSchema={recordValidation}
      validateOnChange
    >
      {({
        setFieldValue,
        handleSubmit,
        isSubmitting,
        isValid,
        errors,
        touched,
      }) => (
        <Form onSubmit={handleSubmit} autoComplete="off">
          <Box margin={1}>
            <Field
              component={TextField}
              name="title"
              label="Title"
              variant="standard"
              fullWidth
            />
          </Box>
          <Box margin={1}>
            <Field
              name="artists"
              multiple
              component={Autocomplete}
              freeSolo
              options={artistOpts}
              getOptionLabel={(option: any) => option.display}
              fullWidth
              renderInput={(params: AutocompleteRenderInputParams) => (
                <MuiTextField
                  {...params}
                  name="artists"
                  error={touched["artists"] && !!errors["artists"]}
                  helperText={touched["artists"] && !!errors["artists"] ? <Typography variant="caption" component="span">Artists is required</Typography> : undefined}
                  label="Artists"
                  variant="standard"
                />
              )}
              onChange={(event: any, value: any[], reason: any) => {
                if (reason === "createOption") {
                  const newValues = value.map((v, idx) => {
                    if (typeof v === "string") {
                      return {
                        discogsId: 0,
                        value: 0,
                        display: v,
                      };
                    } else {
                      return v;
                    }
                  });
                  setFieldValue("artists", newValues);
                } else {
                  setFieldValue("artists", value);
                }
              }}
            />
          </Box>
          <Box margin={1}>
            <Field
              sx={{ width: "25ch" }}
              component={TextField}
              name="catalogueNumber"
              label="Catalogue number"
              variant="standard"
            />

            <Field
              sx={{ ml: 2, width: "25ch" }}
              select
              component={TextField}
              name="medium"
              label="Medium"
              type="text"
              variant="standard"
            >
              {mediumOptions.map((opt) => (
                <MenuItem key={opt.value} value={opt.value}>
                  {opt.text}
                </MenuItem>
              ))}
            </Field>
          </Box>
          <Box margin={1}>
            <Field
              sx={{ width: "25ch" }}
              component={TextField}
              name="year"
              label="Year"
              variant="standard"
              select
            >
              {range.map((opt) => (
                <MenuItem key={opt} value={opt}>
                  {opt}
                </MenuItem>
              ))}
            </Field>
            <Field
              sx={{ ml: 2, width: "25ch" }}
              component={TextField}
              name="discogsId"
              label="Discogs Database ID"
              variant="standard"
            />
          </Box>
          <Box margin={1}>
            <Field
              component={TextField}
              name="comment"
              label="Comment"
              placeholder="Comment"
              variant="standard"
              fullWidth
              multiline
              maxRows={2}
              minRows={2}
            />
          </Box>
          <ErrorMessage
            name="error"
            render={() => <ValidationErrors errors={errors.error} />}
          />
          <Box sx={{ margin: 1, mt: 3, textAlign: "right" }}>
            <Button
              type="submit"
              color="primary"
              disabled={!isValid || isSubmitting}
              variant="contained"
            >
              {props.mode === "create" ? `Create Record` : `Update Record`}
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
});
